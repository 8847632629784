/**
 * 此配置文件针对 静态路由跳转后，左侧菜单不展示的配置。
 * menuId 菜单ID
 * menuName 菜单名称
 * pId 顶级导航ID
 * parentArr 左侧菜单选中的样式，菜单IDs
 * target 左侧菜单选中的样式，目标菜单名称
 *
*/
const staticRouteMenu = [
    {
        menuId: '020602',
        menuName: 'CoworkingCampusOAOAApprovalFormDetail',
        pId: '01',
        parentArr: ['0100', '020600'],
        target: 'CoworkingCampusOAOAApproval'
    }
];

export default staticRouteMenu;
