export default {
    /**
     * 初始化项目列表
     */
    initTableList(tableData, projectManagement) {
        let initDa = projectManagement.projectDetails.init.projectAttr;

        tableData.map((p, i) => {
            // 1.除去学校logo 学校名称,都是数组
            // 2.当点击后获取下标，根据id找到源数据后拿到下标获取productList对应的对象数据
            p['productName'] = []; // 开通产品名称
            p['projectStatus'] = []; // 状态
            p['createTime'] = []; // 产品开通时间
            p['endTime'] = []; // 产品到期时间
            p['managerName'] = []; // 负责人
            p['managerDeptName'] = []; // 负责部门
            p['projectAttr'] = []; // 产品属性
            p['status'] = []; // 产品状态
            p['projectAttrName'] = []; // 产品属性

            p.productList.forEach((item) => {
                p['productName'].push(item.productName);
                p['projectStatus'].push(this.getValue(item.projectStatus));
                p['createTime'].push(item.createTime);
                p['endTime'].push(item.endTime);
                p['managerName'].push(item.managerName);
                p['managerDeptName'].push(item.managerDeptName);
                p['projectAttr'].push(item.projectAttr);
                p['status'].push(item.status)
            });

            initDa.map((c) => {
                p.projectAttr.map((k) => {
                    if ( k == c.key) {
                        p['projectAttrName'].push(c.value);
                    }
                })

            })
        });

        return tableData;
    },
    getValue (data) {
        switch (data) {
            case '0':
                return '使用中';
            case '1':
                return '已到期';
            case '2':
                return '已停用';
            default:
                break;
        }
    },
    /**
     * 动态表单更改数据后赋值给from
     */
    changeFrom(data, ruleForm) {
        let arr = data;
        arr.forEach((p, i) => {
            Object.keys(ruleForm).forEach((c, idx) => {
                if (p.key == c) {
                    ruleForm[c] = p.value;
                }
            })
        });
    },

    /*
     *   初始化弹窗数据 // initialisingPopUps
     **/
    initialisingPopUps(formDataList, ruleForm, data, vm) {
        // 拼装地区数据
        let arrDown = this.areaDowmData(data, vm),
            arrShow = this.areaShowData(data),
            projectAttrList = vm.$data.projectManagement.projectDetails.dictionaries.projectAttr;

        formDataList.forEach(function (element, index) {
            Object.keys(data).forEach(function (key, idx) {
                if (element.key === key) {
                    element.value = data[key];
                }
            });

        });
        Object.keys(ruleForm).forEach(function (element, index) {
            Object.keys(data).forEach(function (key, idx) {
                if (element === key) {
                    ruleForm[element] = data[key];
                }
            });
        });

        vm.$data.optionsData = arrDown;

        vm.$data.ruleForm.areaCascader = arrShow;

        formDataList.forEach(function (element, index) {
            projectAttrList.forEach(function (el, idx) {
                if (element.key == el.key) {
                    element.value = el.value
                }
            });
        });
        // 学校阶段，年级层级赋值
        ruleForm.gradeLevel = Number(ruleForm.gradeLevel)
        ruleForm.schoolStage = []
        ruleForm.schoolStageList.forEach(item => {
            ruleForm.schoolStage.push(Number(item.schoolStage))
        })

    },
    /*
     *    弹窗更换负责人或清除负责人
     **/
    setResponsible(formDataList, ruleForm, fliterData, tabsTypesSel) {
        let {
            data,
            type
        } = fliterData;
        console.log(type,'type')
        switch (type) {
            case 'add':
                if (data.remark && data.remark == 'userInfo') {
                    this.setNewResponsible(formDataList, ruleForm, data, tabsTypesSel)
                }
                break;
            case 'del':

                this.delResponsible(formDataList, ruleForm, data, tabsTypesSel)
                break;
            default:
                break;
        }

    },
    setNewResponsible(formDataList, ruleForm, data, tabsTypesSel) {
        console.log(data,'datadata')
        let toStr = Object.prototype.toString,
            type = '[object Object]';
        console.log(toStr.call(formDataList) == type,'-=')
        if (toStr.call(formDataList) == type) {
            [formDataList].forEach(function (element, index) {
                if (element.key == 'manager') {
                    element.value = data.deptName;
                }
            });
        } else {
            formDataList.forEach(function (element, index) {
                if (element.key == 'manager') {
                    element.value = data.deptName;
                }
            });
        }

        Object.keys(ruleForm.productList[tabsTypesSel]).forEach(function (key, index) {
            if (key == 'manager') {
                ruleForm.productList[tabsTypesSel][key] = data.deptName;
            }
        });

        console.log(ruleForm.productList[tabsTypesSel],'---')
    },
    delResponsible(formDataList, ruleForm, data, tabsTypesSel) {
	    let toStr = Object.prototype.toString,
		    type = '[object Object]';
	    if (toStr.call(formDataList) == type) {
		    [formDataList].forEach(function (element, index) {
			    if (element.key == 'manager') {
				    element.value = '';
			    }
		    });
	    } else {
		    formDataList.forEach(function (element, index) {
			    if (element.key == 'manager') {
				    element.value = '';
			    }
		    });
	    }

	    Object.keys(ruleForm.productList[tabsTypesSel]).forEach(function (key, index) {
		    if (key == 'manager') {
			    ruleForm.productList[tabsTypesSel][key] = '';
		    }
	    });
    },
    /*
     *    拼装所在地区下拉数据
     */
    areaDowmData(data, vm) {
        vm.$data.optionsData.forEach(function (element, index) {
            if (element.value == data.provinceCode) {
                let obj = {
                    value: data.cityCode.toString(),
                    label: data.cityName,
                    children: [{
                        value: data.areaCode,
                        label: data.areaName,
                        leaf: "leaf",
                    }]
                }
                element.children.push(obj);
            }
        });
        return vm.$data.optionsData;
    },
    /*
     *   获取地区 二级或三级地址
     */
    getOptionsReg(data, optionsData, parentCodeList, fn, parentCodeObj) {
        let arr = [],
            level = fn.node.level;
        data.forEach(function (element) {
            if (parentCodeList.level == 1) {
                arr.push({
                    value: element.areaCode,
                    label: element.areaName,
                })
            } else if (parentCodeList.level == 2) {
                arr.push({
                    value: element.areaCode,
                    label: element.areaName,
                    leaf: level >= 2
                })
            }

        });

        if (parentCodeList.level == 1) {
            optionsData.forEach(function (element) {
                element.children = [];
                data.forEach(function (el, idx) {
                    if (el.parentCode == element.value) {
                        fn.fn(arr);
                        element.children = arr;
                    }

                });
            });

        } else if (parentCodeList.level == 2) {
            optionsData.forEach(function (element, index) {
                element.children.forEach(function (elem, index) {
                    if (elem.value === parentCodeObj.parentCode) {
                        fn.fn(arr);
                        elem.children = arr;
                    }
                });
            });
        }

    },
    /*
     *    拼装所在地区显示数据
     */
    areaShowData(data) {
        let arr = [];
        arr.push(data.provinceCode);
        arr.push(data.cityCode);
        arr.push(data.areaCode);
        return arr;
    },
    /*
     *    提交数据
     */
    upFromData(ruleForm, formDataList, optionsData, _this) {
        let fromObj = JSON.parse(JSON.stringify(ruleForm));

        formDataList.forEach((el) => {
            if (el.key == "projectAttr") {
                el.list.forEach((c) => {
                    if (el.value === c.label) {
                        el.value = c.value;
                    }
                })
            }
        });
        // 给地区code 赋值
        Object.keys(fromObj).forEach((key) => {
            if (key === 'provinceCode') {
                fromObj['provinceCode'] = fromObj['areaCascader'][0];
            }
            if (key === 'cityCode') {
                fromObj['cityCode'] = fromObj['areaCascader'][1] + '';
            }

            if (key === 'areaCode') {
                fromObj['areaCode'] = fromObj['areaCascader'][2] + '';
            }
        });
        // 通过地区code 给 地区名称赋值
        optionsData.forEach((item) => {
            if (item.value == fromObj['provinceCode']) {
                fromObj['provinceName'] = item.label;
            }
            if (item.children) {
                item.children.forEach((c => {
                    if (c.value == fromObj['cityCode']) {
                        fromObj['cityName'] = c.label;
                    }

                    if (c.children) {
                        c.children.forEach((sub) => {
                            if (sub.value == fromObj['areaCode']) {
                                fromObj['areaName'] = sub.label;
                            }
                        })
                    }
                }))
            }
        })
        // 删除地区数组
        delete fromObj['areaCascader'];

        formDataList.forEach((item) => {
            Object.keys(fromObj).forEach((key) => {
                if (item.key === key) {
                    fromObj[key] = item.value
                }
            })
        })

        // 学校年级阶段赋值
        // fromObj.payway = fromObj.payWay
        fromObj.gradeLevelName = _this.gradeList.filter(item => item.id === fromObj.gradeLevel)[0].name
        fromObj.schoolStageList = []
        fromObj.schoolStage.forEach(item => {
            fromObj.schoolStageList.push({
                schoolStage: item,
                schoolStageName: _this.stageList.filter(itm => itm.id === item)[0].name
            })
        })
        return fromObj;
    },
    //获取当前一年后的时间，日期格式  传入值格式为2021-08-30
    currentDateLater() {
        let date = new Date(),
            year = date.getFullYear(),
            month = date.getMonth() + 1,
            day = date.getDate();
        if (month < 10) {
            month = "0" + month;
        }
        if (day < 10) {
            day = "0" + day;
        }
        var changeDate = year + "-" + month + "-" + day,
            saliDate = changeDate.split("-"),
            riNum = 0,
            yueNum = 0,
            nianNum = saliDate[0];
        // //判断月 同时判断年
        if (saliDate[1] - 1 <= 0 && saliDate[2] == "01") {
            yueNum = 12;
            riNum = 31;
            nianNum = nianNum;

            return nianNum + "-" + yueNum + "-" + riNum;
            return false;
        } else {
            yueNum = saliDate[1];
            nianNum = nianNum - 0 + 1;
        }
        //当 日 是01 的时候要判断当前下一个月是否为31 还是30天  在判断一个是否为闰年  2月份是29 还是28
        if (saliDate[2] == "01") {
            switch (saliDate[1] - 1) {
                case 1:
                case 3:
                case 5:
                case 7:
                case 8:
                case 10:
                case 0: //0就是12月  因为是只有点击的是2019-01-01  才会是2018-12-31
                    riNum = 31;
                    yueNum = "0" + (saliDate[1] - 1);
                    break;
                case 4:
                case 6:
                case 9:
                case 11:
                    riNum = 30;
                    yueNum = "0" + (saliDate[1] - 1);
                    break;
            }
            if (saliDate[1] - 1 == 2) {
                yueNum = "0" + (saliDate[1] - 1);
                //判断是否为闰年
                if (this.isLeap(saliDate[0]) == 1) {
                    riNum = 29;
                } else {
                    riNum = 28;
                }
            }
        } else {
            if (saliDate[2] - 1 <= 9) {
                riNum = "0" + (saliDate[2] - 1);
            } else {
                riNum = saliDate[2] - 1;
            }
        }
        return nianNum + "-" + yueNum + "-" + riNum + ' 23:59:59';
    },
    //判断是否为闰年  若为闰年，返回1，反之则返回0
    isLeap(year) {
        if ((year % 4 == 0 && year % 100 != 0) || (year % 400 == 0)) {
            return 1;
        }
        return 0;
    }

}
