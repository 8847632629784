class JumpMethod  {
    constructor (data) {
        this.todo = data.todo;
        this.info = data.info;
        this.methodVue = data.methodVue;
        this.init();
    }

    init () {
        this.bindEvent()
    }

    bindEvent () {
        switch (this.todo) {
            case 'internal':
                this.methodVue.$router.push({name: this.info})
                break;

        }
    }
}

class Transit {
    constructor (mode) {
        this.mode = mode.mode;
        this.methodVue = mode._Vue;
        this.init()
    }

    init () {
        this.deconstruction()
    }

    deconstruction () {
        const [type, todo, info] = this.mode.split(':');

        switch (type) {
            case 'url':
                new JumpMethod({todo, info, methodVue: this.methodVue})
                break;
            default:
                this.methodVue.$router.push({name: 'FirstPage'})
                break;

        }

    }
}

export { Transit }
