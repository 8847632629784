export default {
  name: 'deviceManagementOperates',
  // 初始化统计数据
  initStaticData(resData, staticData) {
    staticData.forEach(item => {
      Object.keys(resData).forEach(key => {
        if (item.key === key) {
          item.num = resData[key]
        }
      })
    })
  },
  // 查询
  primaryFilterData(data,listQuery) {
      console.log(data,"data")
    data.forEach(item => {
          Object.keys(listQuery).forEach((key) => {
            if (item.key == key) {
              listQuery[key] = item.value
            }
          })
        if(item.key == 'createTime'){
            if(item.value && item.value.length > 1){
               listQuery.startTime = item.value[0]
                listQuery.endTime = item.value[1]
            } else {
                listQuery.startTime = ''
                listQuery.endTime = ''
            }
        }
    })
  },
  // 重置
  resetFilterData(data,listQuery) {
    data.forEach((p, i) => {
      Object.keys(p).forEach((c) => {
        if (c === 'value') {
          p[c] = ''
        }
      })
    })
    let args = ['pageNum', 'pageRow']
    Object.keys(listQuery).forEach((p) => {
      if (!args.includes(p)) {
        listQuery[p] = ''
      }
    })
  },
}
