export default {
    /*
    *   页面: 组织架构
    *   初始化:格式化数据为树形结构，渲染页面
    * */
    setDateForTree (data) {
        let parent = data.filter((p) => {return p.parentId == 0 });
        let children  = data.filter((p) => {return p.parentId != 0 });
        let arr =  this.setTreeChildren(parent, children);
        return arr;
    },
    setTreeChildren (parent, children) {
        if(parent.length == 0) {
            parent = children;
        } else {
            parent.map((p) => {
                children.map((c,i) => {
                    if (p.id == c.parentId) {
                        let _c = JSON.parse(JSON.stringify(children));
                        _c.splice(i, 1);
                        this.setTreeChildren([c], _c)
                        if (p.children) {
                            p.children.push(c)
                        } else {
                            p.children = [c]
                        }
                    }
                })
            })
        }
        return parent;
    },
    finedFa (table_data, id) {
        let newTreeList = []
        table_data.filter((item) => {
            if (item.id == id.parentId || item.id == id.id) {
                newTreeList.push(item)
            } else {
                if (item.children) {
                    item.children.forEach((ite) => {
                        if(ite.id == id.parentId) {
                            newTreeList.push(ite)
                        } else {
                            this.finedFa([ite], id)
                        }
                    })
                }
            }
        })

        return newTreeList;
    },
    getTimer () {
        let date = new Date(),
            year = date.getFullYear(),
            month = date.getMonth() + 1,
            day = date.getDate();
        if (month < 10) {
            month = "0" + month;
        }
        if (day < 10) {
            day = "0" + day;
        }
        let nowDate = year + "-" + month + "-" + day;
        return nowDate;
    },

    /*
    * 筛选提交数据
    * */
    filterHeaderFrom(data, listQuery) {
        // let obj = {};
        // let nData = data.filter((c) => {
        //    return c.value !== "";
        // });
        data.forEach((item) => {
            // obj[item.key] = item.value;
            listQuery[item.key] = item.value;
        });
        // return obj;
        return listQuery;
    },
    // 重置
    resetFilterData(data,listQuery) {
        data.forEach((p, i) => {
            Object.keys(p).forEach((c) => {
                if (c === 'value') {
                    p[c] = ''
                }
            })
        })
        let args = ['pageNum', 'pageRow']
        Object.keys(listQuery).forEach((p) => {
          if(!args.includes(p)) {
            listQuery[p] = ''
          }
        })
        listQuery.pageNum = 1
    },
    /*
    *  重置弹窗数据
    * */
    resetDialogForm (vm) {
        let _vm = vm
        Object.keys(vm.ruleForm).forEach(key => {
            if (key === 'permissionIds') {
                vm.ruleForm[key] = []
            } else {
                vm.ruleForm[key] = ''
            }
        })
        vm.checkedArr = []
        vm.$refs.dialogFormData.$refs.treeRef.$refs.treeRef.setCheckedKeys([])
    }
}
