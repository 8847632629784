import { changeRules } from '@/assets/data/SummaryTeacher/formRules.js'
function getNewRules () {
    let rulesFn = {
        '11': function () {
            return changeRules.allRules;
        },
        '22': function () {
            return changeRules.defaultRule;
        },
        '12': function () {
            return changeRules.correlationTeachers;
        },
        '21': function () {
            return changeRules.correlationClassTeacher;
        }
    }

    return rulesFn;
}


export {
    getNewRules
}
