export default {
    /*
    *   初始化,获取面包屑数据
    */
    pageChangeInit(breadcrumbList, newVal) {
        const breadcrumb_list = JSON.parse(JSON.stringify(breadcrumbList));
        let path = newVal.path;

        let val = this.getValue(breadcrumb_list, path);
        
        let breadcrumbVal = this.getNewVal(val, breadcrumb_list);
        return breadcrumbVal;
    },
    getValue (breadcrumbList, path) {
        let breadcrumb_value = [path];
        this.startBreadcrumb(breadcrumb_value, breadcrumbList, breadcrumbList, path);
        return breadcrumb_value;
    },
    startBreadcrumb (breadcrumbValue, breadcrumbList, _breadcrumbList, path) {
        if (!Array.isArray(breadcrumbList)) return;
        for (let breadcrumbItem of breadcrumbList) {

            if (!Array.isArray(breadcrumbItem.children)) continue;
            let index = breadcrumbItem.children.findIndex(item => item.path === path);
            
            if (index >= 0) {
                breadcrumbValue.unshift(breadcrumbItem.path);
                if(_breadcrumbList.findIndex(item => item.path === breadcrumbItem.path) < 0)
                this.startBreadcrumb(breadcrumbValue, _breadcrumbList, _breadcrumbList,breadcrumbItem.path);
            } else {
                this.startBreadcrumb(breadcrumbValue, breadcrumbItem.children, _breadcrumbList,path);
            }
        }
    },

    getNewVal (val, breadcrumbList) {
        var arr = [];
        breadcrumbList.forEach((p, idx) => {
            val.forEach((c, i) => {
                if (p.path === c) {
                    let obj = {
                        title: p.title,
                        path: p.path,
                        type: p.children ? false : true,
                    };
                    arr.push(obj);
                };
            });
            if (p.children) {
                arr = arr.concat(this.getNewVal(val, p.children));
            }
        });

        return arr;
    }
}