/*
 * @Author: 武东超
 * @Date: 2022-11-04 11:49:41
 * @LastEditors: 孟睿臻 2905421741@qq.com
 * @LastEditTime: 2024-09-05 10:12:45
 * @FilePath: /cloud_campus_Front-end/cloud_campus_Front-end/school_end/src/operations/teachingResearchCourseScheduleTemp.js
 * @Description: TeachingResearchCourseScheduleTemp类
 */
class CourseScheduleTemp {
    constructor () {}
    sortingQueryList (data, filterQueryList, vm) {
        let weekDay,
            lastMonday,
            lastSunday;

        Object.keys(filterQueryList).forEach((key) => {
            Object.keys(data).forEach((subKey) => {
                if (key === subKey) {
                    filterQueryList[key] = data[subKey];
                }
            })
        })

        switch (data.dayWeek) {
            case 'day':
                filterQueryList.selectDay = data.selectDay;
                filterQueryList.startTime = '';
                filterQueryList.endTime = '';
                break;
            case 'week':
                if (data.selectDay) {
                    weekDay = vm.$moment(data.selectDay).format('E');
                    lastMonday = vm.$moment(data.selectDay).subtract(weekDay - 1, 'days').format('YYYY-MM-DD');//周一日期
                    lastSunday = vm.$moment(data.selectDay).add(7 - weekDay, 'days').format('YYYY-MM-DD');//周日日期

                    filterQueryList.startTime = lastMonday;
                    filterQueryList.endTime = lastSunday;
                    filterQueryList.selectDay = '';

                }

                break;
            default:
                break;
        }
    }

    getTimeDay (vm) {
        var now = vm.$moment().locale('zh-cn').format('YYYY-MM-DD');
        return now
    }

    getDayList (filterQueryList, data, list, courseList, vm) {
        // console.log(filterQueryList, data, list, courseList,'filterQueryList-day')

        let weekName = vm.$moment(filterQueryList.selectDay,'YYYY-MM-DD').format('E')
        // console.log(weekName,'weekName')
        list[0].label = filterQueryList.selectDay + ' ' +this.getWeekName(weekName);

        courseList.forEach((item) => {
            // console.log(filterQueryList.selectDay,'filterQueryList.selectDay',item)
            let obj = {
                _uuid: item.id,
                _uuName: item.label,
                _time: item.time,
                _day: filterQueryList.selectDay,
                _class: filterQueryList.scheduleClassId,
                _week: vm.$moment(filterQueryList.selectDay,'YYYY-MM-DD').format('E'),
                _deadline: filterQueryList.selectDay +' '+ item.starTime,
                _timestamp: new Date(filterQueryList.selectDay +' '+ item.starTime).getTime(),
                _gradeId: item.gradeId,
                operateShow:false
            };
            vm.$set(obj, 'checkState', false)
            list[0].child.push(obj);
        })

        let str = Object.prototype.toString,
            type = '[object Object]';

            if(str.call(data) !== type) {
                list[0].child.forEach((item, index) => {
                    data.forEach((subItem) => {
                        if (item._uuid === subItem.restTimeId) {
                            list[0].child.splice(index, 1, Object.assign(subItem, item))
                        }
                    })
                })
            }

        // console.log(list,'list-list')
    }

    getWeekList (filterQueryList, data, list, courseList, vm) {

        let dataList = this.getDateBetween(filterQueryList.startTime, filterQueryList.endTime)
        // console.log(dataList, filterQueryList, data, list, courseList,'filterQueryList-week')
        for(let i = 0; i < dataList.length; i++ ) {
            let label = this.getNewLabel(i, dataList[i]);
            // console.log(dataList[i],'dataList[i]')
            let obj = {
                id: i,
                label: label,
                data: dataList[i], // 年月日 2023-01-30
                child: []
            }

            list.push(obj);
        };

        list.forEach((item, index) => {
            courseList.forEach((subItem, subIndex) => {
                let obj = {
                    _uuid: subItem.id,
                    _uuName: subItem.label,
                    _time: subItem.time,
                    _day: item.data,
                    _class: filterQueryList.scheduleClassId,
                    _deadline: item.data +' '+ subItem.starTime,
                    _timestamp: new Date(item.data +' '+ subItem.starTime).getTime(),
                    _gradeId: subItem.gradeId,
                    operateShow:false

                };
                vm.$set(obj, 'checkState', false)
                item.child.push(obj);
            });
        })

        let str = Object.prototype.toString,
            type = '[object Object]';

        if(str.call(data) !== type) {
            list.forEach((item, index) => {
                item.child.forEach((pItem, pIndex) => {
                    data.forEach((subItem) => {
                        if (item.data === subItem.scheduleTime && pItem._uuid == subItem.restTimeId) {
                            item.child.splice(pIndex, 1, Object.assign(subItem ,pItem))
                        }
                    })
                })
            })
        }

        // console.log(list, courseList, 'shuju++')
    }

    getNewLabel (i, data) {
        switch(i) {
            case 0:
                return `周一（${data}）`;
            case 1:
                return `周二（${data}）`;
            case 2:
                return `周三（${data}）`;
            case 3:
                return `周四（${data}）`;
            case 4:
                return `周五（${data}）`;
            case 5:
                return `周六（${data}）`;
            case 6:
                return `周日（${data}）`;
        }
    }

    getWeekName (data) {
        switch(data) {
            case '1':
                return `周一`;
            case '2':
                return `周二`;
            case '3':
                return `周三`;
            case '4':
                return `周四`;
            case '5':
                return `周五`;
            case '6':
                return `周六`;
            case '7':
                return `周日`;
        }
    }

    getDateBetween(start, end) {
        var result = [];
        //使用传入参数的时间
        var startTime = new Date(start);
        var endTime = new Date(end);
        while (endTime - startTime >= 0) {
            let year = startTime.getFullYear();
            let month = startTime.getMonth();
            month = month<9?'0'+(month+1):month+1;
            let day = startTime.getDate().toString().length == 1 ? "0" + startTime.getDate() : startTime.getDate();
            //加入数组
            result.push(year + "-" + month + "-" + day);
            //更新日期
            startTime.setDate(startTime.getDate() + 1);
        }
        return result;
    }

}

export {
    CourseScheduleTemp
}
