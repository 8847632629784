export default {
    /*
    *   初始化页面
    * */
    init (template_list, templateTitle, themeBtnList) {
        template_list.forEach((p, idx) => {
            if (p.sel === true) {
                Object.keys(p).forEach((key) => {
                    templateTitle.forEach((i) => {
                        if (key == i.key) {
                            i.colorValue = p[key]
                            // console.log(key, )
                        }
                    })
                })

                // console.log(template_list, templateTitle, 'templateTitle')
                // templateTitle[0].colorValue = p.headerColor;
                // templateTitle[2].colorValue = p.textColor;
                // if (p.type === 'twoColumns') {
                //     templateTitle[1].colorValue = p.barColor;
                //     templateTitle[1].show = true;
                //     templateTitle[2].show = true;

                // } else if (p.type === 'enterprise'){
                //     templateTitle[1].show = false;
                //     templateTitle[2].show = true;
                // }
            }
        });
        // console.log(templateTitle,'templateTitletemplateTitle')
        // themeBtnList.forEach((p, idx) => {
        //     if (p.sel === true) {
        //         templateBtnTitle[0].colorValue = p.colorValue;
        //     }
        // })
    },
    /*
    *   筛选模版数据
    * */
    filterTemplate (data, vm) {
        // console.log(data,vm, 'vavava')
        if (!data.type) {
            vm.$data.addTemplateType = true;
        } else {

            vm.$data.templateTitle[0].colorValue = data.headerColor;
            vm.$data.templateTitle[1].colorValue = data.barColor;
            vm.$data.templateTitle.forEach((c, idx) => {

                if (c.id !== '1' && c.id !== '4' && data.type === 'enterprise') {
                    c.show = false;
                } else {
                    c.show = true;
                }
            })

            vm.$data.template_list.forEach((c, i) => {
                if (c.id == data.id) {
                    c.sel = true;
                } else {
                    c.sel = false;
                }
            })
        }
    },
    /*
    *   添加模版
    */
    addTemplate (data, vm) {
        const templateItem = {
            'twoColumns': {
                id: 0,
                type: 'twoColumns',
                title: '自定义13',
                headerColor: "#2E4A73", // 头部颜色
                barColor: "#2E4973", // 导航栏背景颜色
                barSelectedColor: "#2968A5", //导航栏菜单选中颜色
                barPullDownColor: "#C1CDDB", // 多级菜单下拉选中颜色
                sel: false,
                del: true
            },
            'enterprise': {
                id: 0,
                type: 'enterprise',
                title: '自定义23',
                headerColor: "#2E4A73", // 头部颜色
                barColor: "#2E4973", // 导航栏背景颜色
                barSelectedColor: "#2968A5", //导航栏菜单选中颜色
                barPullDownColor: "#C1CDDB", // 多级菜单下拉选中颜色
                sel: false,
                del: true
            }
        }
        let { radioType, type } = data;

        switch (type) {
            case true:
                let template_list = JSON.parse(JSON.stringify(vm.$data.template_list));
                let index = template_list.length;

                if (vm.$data.radio === '1') {
                    template_list.splice(index - 1, 0, templateItem.twoColumns);
                } else if (vm.$data.radio === '2') {
                    template_list.splice(index - 1, 0, templateItem.enterprise);
                };

                template_list.forEach((c, i) => {
                    c.id = i;
                });
                vm.$data.template_list = template_list;
                break;
            case false:

                break;
            default:
                vm.$data.addTemplate = false;
                vm.$data.radio = '1';
                break;
        }
    },
    // 触发模版选择
    changeTemplateStyle (data, vm) {
        let parent = vm.$parent.$parent.$parent;
        // console.log(parent,'parentparentparent')
        parent.changeTemplateStyle(data, vm)
    },
    // 修改模版颜色
    changeColor (data, template_list, templateTitle) {
        let selTemplate = data;
        template_list.forEach((c) => {
            if (c.sel) {
                Object.keys(c).forEach((key) => {
                    // console.log(selTemplate.key,key,'selTemplate.key')
                    if (selTemplate.key == key) {
                        // console.log(key,'key')
                        c[key] = selTemplate.colorValue;
                    }
                })
                // Object.keys(selTemplate).forEach((item) => {
                //     console.log(selTemplate[item], 'leu')
                //
                // })
                // if (selTemplate.id === '1' && selTemplate.show) {
                //     c.headerColor = selTemplate.colorValue;
                // } else if (selTemplate.id === '2' && selTemplate.show) {
                //     c.barColor = selTemplate.colorValue;
                // } else if (selTemplate.id === '4' && selTemplate.show) {
                //     c.textColor = selTemplate.colorValue;
                // }
            }
        });
        // console.log(template_list,'template_listtemplate_list')
        templateTitle.forEach((item) => {
            Object.keys(selTemplate).forEach((c) => {
                if (item['key'] == c) {
                    item.colorValue = c.colorValue;
                }
            })
        })

        // console.log(template_list,'template_listtemplate_list')
    },
    // 删除模版
    delTemplate (data, vm) {
        vm.$data.template_list.forEach((p, idx) => {
            if (p.id === data.id) {
                vm.$data.template_list.splice(idx, 1);
            }
        });

        this.filterTemplate (vm.$data.template_list[0], vm)
        vm.add();
    },
    /*
    *   按钮
    */
    // 选择按钮
    changeBtnSty (data, vm) {
        vm.$data.themeBtnList.forEach((p, idx) => {
            if (data.id === p.id) {
                p.sel = true;
                vm.$data.templateBtnTitle[0].colorValue = p.colorValue;
            } else {
                p.sel = false;
            }
        });
    },
    // 选中颜色
    changeBtnColor (data, themeBtnList) {

        themeBtnList.forEach((p, i) => {
            if (p.sel) {
                p.colorValue = data.colorValue;
            }
        })
    }
}
