import { changeRules } from '@/assets/data/SummaryStudent/formRules.js'

function getNewRules () {
    let rulesFn = {
        '1': function () {
            return changeRules.defaultRule;
        },
        '2': function () {
            return changeRules.defaultRule;
        }
    }

    return rulesFn;
}


export {
    getNewRules
}
