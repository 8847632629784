import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store';
import {
    getToken,
    removeToken,
    setToken
} from '@/libs/auth';
import {
    generateRouter,
    param2Obj
} from '@/libs/utils';
import { title } from "@/libs/settings";
import saveBehaviorRecord from '@/libs/behavioralAnalysis.js'

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const routes = [
    {
        path: "/",
        redirect: "/firstPage",
    },
    {
        path: "/firstPage",
        name: "FirstPage",
        component: () => import("../views/FirstPage.vue"),
    },
    // 集团登录
    {
        path: "/groupLogin",
        name: "GroupLogin",
        component: () => import("../views/GroupLogin.vue"),
    },
    // 集团选择校园
    {
        path: "/groupSchool",
        name: "GroupSchool",
        component: () => import("../views/GroupSchool.vue"),
    },
    // 校园登录
    {
        path: "/login",
        name: "login",
        component: () => import("../views/Login.vue"),
    },
    // 第三方单点登录页面
    {
        path: "/autoJump",
        name: "autoJump",
        component: () => import("../views/AutoJump.vue"),
    },
    {
        path: "/themeColor",
        name: "themeColor",
        component: () => import("../views/ThemeColor.vue"),
        meta: {
            title: "我的主题",
        },
    },
    // 个人档案
    {
        path: "/user/userProfile",
        name: "UserProfile",
        component: () => import("../views/UserProfile.vue"),
        meta: {
            title: "个人档案",
        },
    },
    // 个人账户
    {
        path: "/user/changePassword",
        name: "ChangePassword",
        component: () => import("../views/UserChangePassword.vue"),
        meta: {
            title: "个人账户",
        },
    },
    // 消息提醒
    {
        path: "/messageList",
        name: "MessageList",
        component: () => import("../views/MessageList.vue"),
        meta: {
            title: "消息提醒",
        },
    },
    // 待办
    {
        path: "/dealtList",
        name: "DealtList",
        component: () => import("../views/dealtList.vue"),
        meta: {
            title: "待办事项",
        },
    },
    // 表单详情
    {
        path: "/coworking/campusOA/OAApproval/formDetail",
        name: "CoworkingCampusOAOAApprovalFormDetail",
        component: () =>
            import("../views/CoworkingCampusOAOAApprovalFormDetail.vue"),
        meta: {
            selMenuId: "020602", // oa审批
        },
    },
    // 申请详情
    {
        path: "/coworking/campusOA/oAApproval/applyDetail",
        name: "CoworkingCampusOAOAApprovalApplyDetail",
        component: () =>
            import("../views/CoworkingCampusOAOAApprovalApplyDetail.vue"),
        meta: {
            selMenuId: "020602", // oa审批
        },
    },
    {
        path: "/campusOA",
        name: "CampusOA",
        component: () => import("../views/CampusOA.vue"),
    },
    // 异常反馈页面
    {
        path: "/abnormal/feedback",
        name: "abnormalFeedback",
        component: () => import("../views/abnormalFeedback.vue"),
    },
    // 钉钉登录页面
    {
        path: "/ding-login",
        name: "DingLogin",
        component: () => import("@/views/DingLogin.vue"),
    },
    // 异常反馈页面(钉钉单点)
    {
        path: "/abnormalFeedbackd",
        name: "abnormalFeedbackd",
        component: () => import("../views/abnormalFeedbackd.vue"),
    },
    {
        path: "/ui",
        name: "ui",
        component: () => import("../views/UI.vue"),
    },
    {
        path: "/thirdPartyJump",
        name: "ThirdPartyJump",
        component: () => import("../views/ThirdPartyJump.vue"),
    },
    {
        path: "/usbKeyJump",
        name: "usbKeyJump",
        component: () => import("../views/usbKeyJump.vue"),
    },
    {
        path: "/jumpPage",
        name: "JumpPage",
        component: () => import("../views/JumpPage.vue"),
    },
    // 建设中页面
    {
        path: "/building",
        name: "Building",
        component: () => import("../views/Building.vue"),
    },
    // 中转
    {
        path: "/transit",
        name: "Transit",
        component: () => import("../views/Transit.vue"),
    },
    // 消息列表
    {
        path: "/notificationList",
        name: "NotificationList",
        component: () => import("../views/NotificationList.vue"),
    },
    {
        path: "*",
        name: "NotFound",
        component: () => import("../views/NotFound.vue"),
    }
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

const resetRouter = () => {
    const newRouter = new VueRouter({
        mode: "hash",
        base: process.env.BASE_URL,
        routes,
    });
    router && (router.matcher = newRouter.matcher);
};

let param = param2Obj(window.location.search)
if (param.token) {
    window.location.search = '?projectMark=' + param.projectMark
    store.state.projectMark = param.projectMark || ''
    setToken(param.token)
}

const whiteList = ['/campusOA', '/abnormalFeedbackd', '/login/'] // no redirect whitelist
const thirdParty = ['/thirdPartyJump']
const usbKey = ['/usbKeyJump','/autoJump']
// 路由管理
router.beforeEach(async (to, from, next) => {

    store.state.projectMark = param.projectMark || ''
    document.title = store.state.schoolName || title;

    saveBehaviorRecord(to)
    if (whiteList.includes(to.path)) {
        return next()
    }

    if (thirdParty.includes(to.path)) {
        return next()
    }

    if (usbKey.includes(to.path)) {
        return next()
    }

    if (to.query.token) {
        setToken(to.query.token)
    }

    const token = getToken();
    if (token) {
        if (!store.state.hasAuth) {
            await store.dispatch('getUserInfo');
            const newRoutes = generateRouter(store.state.permission.formatRouter);
            resetRouter()
            console.debug(newRoutes);
            router.addRoutes(newRoutes);
            next({
                path: to.fullPath
            })
        } else {
            next()
        }
    } else {
        if (to.path === '/login' || to.path === '/GroupLogin' || to.path === '/autoJump') {
            removeToken()
            next()
        } else {
            //第一次会走到这个位置，然后重新触发路由钩子函数beforeEach,
            //当重新触发钩子函数的时候这时的to.path已经变为/login
            if (to.path === '/groupSchool') {
                removeToken()
                next('/GroupLogin')
            } else {
                removeToken()
                next('/login')
            }
        }
    }
    next()
})

export default router;
